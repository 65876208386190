<template>
  <div class="o-signin">
    <SigninForm></SigninForm>
  </div>
</template>

<script>
import SigninForm from '@/components/SigninForm.vue'

export default {
  name: 'SigninBypass',
  components: {
    SigninForm
  },
}
</script>
