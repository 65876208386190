<template>
  <div class = "c-alpha-signin">
    <h1 class="c-alpha-signin__title-body"><span class = "c-alpha-signin__title-body_blueblack">Sign in </span>to Continue</h1>
    <div class = "c-alpha-signin__form-container">
      <div class = "c-alpha-signin__form-group">
        <input class = "c-alpha-signin__input" :class = "{'c-alpha-signin__input_error':errorText}" placeholder = "email address" v-model="input.email">
      </div>
      <div class = "c-alpha-signin__form-group">
        <input class = "c-alpha-signin__input" :class = "{'c-alpha-signin__input_error':errorText}" placeholder = "password" :type = "showPassword?'text':'password'" v-model="input.password">
        <img src="@/assets/images/old-alpha/eye.png" class = "c-alpha-signin__password-show" v-if = "!showPassword" @click = "togglePassword">
        <img src="@/assets/images/old-alpha/eye-slash.png" class = "c-alpha-signin__password-show" v-if = "showPassword" @click = "togglePassword">
      </div>
      <p class="c-alpha-signin__error" v-if = "errorText">{{errorText}}</p>
      <button class = "c-alpha-signin__button" :class="{'c-alpha-signin__button_disabled': !validateForm}" @click = "executeGeetest">Sign In</button>
    </div>
    <p class = "c-alpha-signin__signin-bottom">
      <a class = "c-alpha-signin__signin-bottom-link" @click='routerRedirect("forgot-password")'>Forgot my password</a>&nbsp; | &nbsp;<a class = "c-alpha-signin__signin-bottom-link" @click='routerRedirect("signup")'>Create an account</a>
    </p>
    <Geetest ref="geetest" @verify="authenticate"></Geetest>
    <InvalidCaptchaAlert v-if = "invalidCaptchaStatus"></InvalidCaptchaAlert>
  </div>
</template>

<script>
import InvalidCaptchaAlert from '@/components/InvalidCaptchaAlert'
import Geetest from '@/components/Geetest'
import publicKey from 'raw-loader!../../public/key/sparrowSecurityAuthPub.key'
export default {
  components: {Geetest, InvalidCaptchaAlert},
  data() {
    return {
      input:{
        email: '',
        password: '',
        t:Date.now()
      },
      forge: require('node-forge'),
      processing: false,
      maintenance:false,
      publicKey:publicKey,
      pubKey:null,
      captchaRes:'',
      invalidCaptchaStatus: false,
      errorText:'',
      showPassword:false,
    }
  },
  created() {
    this.$eventHub.$on('invalidCaptchaStatus', (status) =>{
      this.invalidCaptchaStatus = status
    });
  },
  mounted(){
    this.pubKey = this.forge.pki.publicKeyFromPem(this.publicKey);
  },
  methods:{
    checkMaintenance() {
      this.$http.get(this.createApiUrlPricing('get_platform_status')).then(response => {
        var apistatus = response.body.api_status
        var pricingstatus = response.body.pricing_status
        if(apistatus=="0" || pricingstatus=="0") {
          this.maintenance = true
          this.$router.push('/maintenance').catch(() => {})
        } else {
          this.maintenance = false
        }
      }, err => {
        console.log(err)
        // this.showMessageError(err, false , err.status)
      })
    },
    encryptInput:function(){
      this.input.t = Date.now();
      const enc = this.symEncrypt(this.input);
      const key = this.pubKey.encrypt(enc.key, 'RSA-OAEP', {
        mgf1: {
          md: this.forge.md.sha1.create(),
        },
      });
      const iv = this.pubKey.encrypt(enc.iv, 'RSA-OAEP', {
        mgf1: {
          md: this.forge.md.sha1.create(),
        },
      });
      return {
        ciphertext: this.forge.util.encode64(enc.ciphertext),
        key: this.forge.util.encode64(key),
        iv: this.forge.util.encode64(iv),
      };
    },
    symEncrypt:function(dt){
      const key = this.forge.random.getBytesSync(32);
      const iv = this.forge.random.getBytesSync(16);
      const encCipher = this.forge.aes.createEncryptionCipher(key, 'CBC');
      encCipher.start(iv);
      encCipher.update(this.forge.util.createBuffer(JSON.stringify(dt), 'utf-8'));
      encCipher.finish();
      const ciphertext = encCipher.output.data;
      return {
        key,
        iv,
        ciphertext,
      };
    },
    executeGeetest:function(){
      if(this.processing){
        return false
      }
      this.processing = true;
      if (this.$route.name == 'Signin') {
        this.$refs.geetest.execute()
      } else {
        this.authenticate()
      }
    },
    authenticate: function(captchaRes){
      var encryptedInput = this.encryptInput();
      this.captchaRes = captchaRes
      let _this = this
      let apiResp;
      // this.$http.post("https://api2.sparrowsandbox.com/users/login?g-recaptcha-response="+ captchaRes, encryptedInput).then(response =>{
      if (this.$route.name == 'Signin') {
        apiResp = "users/login?geetest_challenge="+captchaRes.geetest_challenge+"&geetest_seccode="+captchaRes.geetest_seccode+"&geetest_validate="+captchaRes.geetest_validate
      } else {
        apiResp = "users/login?geetest_bypass=true"
      }
      this.$http.post(this.createApiUrlChestNut(apiResp), encryptedInput).then(response =>{
        this.$store.commit('doLogin',response.body.accessToken)
        this.errorText = ''
        this.$http.get(this.createApiUrlChestNut('me')).then(me =>{
          this.$store.commit("setProfileData", me);
          localStorage.setItem('vuex', JSON.stringify({'token': response.body.accessToken.token}));
          if (me.body.userKYCStatus === 'UNSUBMITTED') {
            this.$router.push("/registration-process").catch(() => {});
          } else {
            if (localStorage.getItem('apiKeyURL') === null) {
              if(!me.body.tncAccepted){
                this.$eventHub.$emit('show-terms')
              }
              else{
                window.location = 'dashboard/#/checker'
              }
            } else {
              let url = localStorage.getItem('apiKeyURL')
              window.location = url
            }
          }
        })
      },err => {
        _this.$refs.geetest.reset()
        this.checkMaintenance()
        if(this.maintenance) {
          return false
        } else {
          if(err.body.code=='OTPRequired'){
            this.processing = false
            this.$router.push({ name: 'Signin Twofa', params: {
              email: this.input.email,
              password: this.input.password,
              captchaRes: this.captchaRes
            }}).catch(() => {})
          }
          else if(err.body.code == 'DeviceAuthRequired'){
            this.processing = false
            this.$router.push("/unrecognized").catch(() => {});
          }else if (err.body.code == 'IncorrectEmailPassword') {
            this.errorText = "Email / password is incorrect"
            this.processing = false
          }
          else{
            if(err.status == 0) {
              this.errorText = 'Too many requests received, please try again shortly.'
              this.processing = false
            } else {
              this.errorText = err.body.message;
              this.processing = false
            }
          }
        }
      });
    },
    togglePassword:function(){
      this.showPassword = !this.showPassword
    },
  },
  computed:{
    validateForm:function(){
      return this.input.email && this.input.password
    }
  }
}
</script>
